import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './pages-styling/study.css';
import axios from 'axios';

const TermsOfUse = () => {

  return (
    <div >  

      <div className="my-study-container">  

        <div className='meant-to-align'>
          <div className='align-container'>

            <div className="my-text-container" to="/application-practice">
              <div className="terms-block">

                <h2>
                  1. Introduction
                </h2>

                <h3>
                  Purpose of the Terms of Use
                </h3>

                <p>
                  These Terms of Use (hereinafter referred to as "Terms") govern your access to and use of our 
                  educational platform, including any content, functionality, and services offered on or 
                  through our website https://lernacy.co.za (the "Service"). The Terms are intended to ensure a 
                  safe, fair, and enjoyable experience for all users. By accessing or using the Service, you 
                  agree to comply with and be bound by these Terms. If you do not agree to these Terms, you 
                  must not access or use the Service.
                </p>

                <h3>
                  Identification of Our Business
                </h3>

                <p>
                  Our Service is operated by Lernacy (hereinafter referred to as "Company," "we," "our," or "us"). 
                  Lernacy is committed to providing high-quality educational resources and tools to enhance 
                  learning experiences. We strive to offer innovative solutions that support students, 
                  educators, and institutions in achieving their academic goals.
                </p>

                <label>
                  <strong>
                    Our business details are as follows:
                  </strong>
                </label>

                <ul className='terms-list'>
                  <li>Company Name: <strong>Lernacy</strong></li>
                  <li>Website: <strong>https://lernacy.co.za</strong></li>
                  <li>Contact Email: <strong>admin@lernacy.co.za</strong></li>
                </ul>

                <h3>
                  Contact Information
                </h3>

                <p>
                  If you have any questions, comments, or concerns about these Terms or our Service, please 
                  feel free to contact us. We are here to help and ensure you have the best possible experience 
                  using our Service. You can reach us via the following methods:
                </p>

                <ul className='terms-list'>
                  <li>Email: admin@lernacy.co.za</li>
                  <li>Phone: +27 79 378 8687</li>
                </ul>

                <h3>
                  Acceptance of Terms
                </h3>

                <p>
                  By accessing, browsing, or using the Service, you acknowledge that you have read, understood, 
                  and agreed to be bound by these Terms and our Privacy Policy, which is incorporated herein by 
                  reference. If you do not agree to these Terms or the Privacy Policy, you must immediately 
                  cease using the Service.
                </p>

                <p>
                  Your continued use of the Service following the posting of any changes to these Terms 
                  constitutes acceptance of those changes. It is your responsibility to check these Terms 
                  periodically for changes. If you do not agree to the modified Terms, you must stop using the 
                  Service.
                </p>

                <p>
                  We reserve the right to modify or terminate the Service or any part thereof at any time, with 
                  or without notice. We also reserve the right to refuse service to anyone for any reason at 
                  any time.
                </p>

                <p>
                  Thank you for using Lernacy. We are excited to have you as part of our learning community and 
                  look forward to supporting your educational journey.
                </p>

                <h2>
                  2. User Responsibilities
                </h2>

                <h3>
                  Providing Accurate Information
                </h3>

                <p>
                  As a user of Lernacy, you are expected to provide accurate, complete, and up-to-date 
                  information when creating an account and during any interaction with our Service. This 
                  includes, but is not limited to, your personal details, educational background, and any other 
                  information required for the effective use of the platform. Providing false or misleading 
                  information is strictly prohibited and may result in the termination of your account and 
                  access to the Service.
                </p>

                <h3>
                  Compliance with Laws and Regulations
                </h3>

                <p>
                  As a user of Lernacy, you are required to comply with all applicable local, national, and 
                  international laws and regulations. This includes adhering to the South African Constitution, 
                  which governs social behavior, access to education, and other relevant laws.
                </p>

                <label>
                  Specifically, you agree to:
                </label>
                <ol className='terms-list'>

                  <li>
                    <strong>Respect the Right to Education:</strong><br />Acknowledge and respect the right to 
                    education as enshrined in the South African Constitution, ensuring that your actions on the 
                    platform support and do not hinder the educational progress of others.
                  </li>

                  <li>
                    <strong>Promote a Safe and Respectful Environment:</strong><br />Engage in responsible and 
                    respectful behavior towards other users, including students, educators, and institutions. 
                    Any form of harassment, discrimination, or inappropriate behavior is strictly prohibited.
                  </li>

                  <li>
                    <strong>Abide by Privacy and Data Protection Laws:</strong><br />Protect the privacy and 
                    personal information of others in accordance with the Protection of Personal Information Act 
                    (POPIA). Do not collect, use, or share personal information without appropriate consent.
                  </li>

                  <li>
                    <strong>Avoid Plagiarism and Academic Dishonesty:</strong><br />Ensure that all academic work, 
                    submissions, and interactions are your own original work. Plagiarism, cheating, and any 
                    form of academic dishonesty are strictly prohibited.
                  </li>

                  <li>
                    <strong>Use the Platform for Educational Purposes Only:</strong><br />Utilize the platform solely 
                    for educational purposes and not for any unauthorized commercial activities, political 
                    campaigning, or other non-educational purposes.
                  </li>

                  <li>
                    <strong>Report Violations:</strong><br />Promptly report any violations of these Terms or 
                    any suspicious activities to Lernacy’s support team at <strong>admin@lernacy.co.za</strong> Cooperation 
                    in maintaining a safe and secure learning environment is essential.
                  </li>

                </ol>

                <label>
                  <strong>
                    Consequences of Non-Compliance<br />Failure to comply with these expectations and applicable laws may result in:
                  </strong>
                </label>

                <ul className='terms-list'>
                  <li>Suspension or termination of your account.</li>
                  <li>Legal action as deemed appropriate by relevant authorities.</li>
                  <li>Other consequences as outlined in our policies and applicable laws.</li>
                </ul>

                <p>
                  We are committed to providing a positive and productive educational experience for all users. 
                  Your adherence to these responsibilities is crucial in maintaining the integrity and 
                  effectiveness of the Lernacy platform.
                </p>

                <h3>
                  Account Creation and Security
                </h3>

                <label>
                  Outline the Process for Creating an Account:
                </label>
                <ul className='terms-list'>
                  <li>Users must complete the registration process by providing accurate and current information.</li>
                  <li>Account creation may require verification through email or other means to ensure security.</li>
                </ul>

                <label>
                  Emphasize the Importance of Keeping Account Information Secure:
                </label>
                <ul className='terms-list'>
                  <li>Users are responsible for maintaining the confidentiality of their account credentials.</li>
                  <li>Avoid sharing account details with others and use strong, unique passwords.</li>
                  <li>Promptly notify us of any unauthorized use or security breach of your account.</li>
                </ul>

                <label>
                  Address Responsibilities Related to Account Activity:
                </label>
                <ul className='terms-list'>
                  <li>Users are solely responsible for all activities conducted under their account.</li>
                  <li>Ensure that all interactions and content shared through the account comply with our Terms of Use and legal standards.</li>
                </ul>

                <h2>
                  Use of the Service
                </h2>

                <h3>
                  How the Service Can and Cannot Be Used:
                </h3>
                <ul className='terms-list'>
                  <li>The service is intended for personal or educational use as outlined in the application's purpose.</li>
                  <li>Users may access and utilize features provided by the service in accordance with applicable laws and regulations.</li>
                </ul>

                <h3>
                  Prohibited Activities:
                </h3>
                <label>Prohibited activities include but are not limited to:</label>
                <ul className='terms-list'>
                  <li>Spamming, phishing, or any form of unauthorized advertising.</li>
                  <li>Harassing or threatening behavior towards others.</li>
                  <li>Engaging in illegal activities or promoting illegal content.</li>
                  <li>Misrepresenting oneself or providing false information.</li>
                </ul>

                <h3>
                  Any Usage Limits or Restrictions:
                </h3>
                <ul className='terms-list'>
                  <li>Usage limits may apply to certain features or actions within the service.</li>
                  <li>We reserve the right to restrict or suspend access to the service for users violating our Terms of Use or engaging in disruptive behavior.</li>
                </ul>

                <h2>
                  Intellectual Property
                </h2>

                <h3>
                  Ownership of Content and Intellectual Property Rights:
                </h3>

                <p>
                  All content and materials provided on [Your Application/Website Name] ("the Service"), 
                  including but not limited to text, graphics, logos, icons, images, audio clips, digital 
                  downloads, data compilations, and software, are the property of [Your Company Name] or its 
                  content suppliers and protected by international copyright laws.
                </p>

                <h3>
                  User Responsibilities:
                </h3>

                <label>
                  By using the Service, you agree to respect the intellectual property rights of [Your Company 
                  Name] and others. This includes but is not limited to:
                </label>
                <ul className='terms-list'>

                  <li><strong>Copyright: </strong>Users must not reproduce, distribute, modify, create derivative works of, 
                    publicly display, publicly perform, republish, download, or store any content from the 
                    Service without the prior written permission of Lernacy or the respective 
                    copyright holder.
                  </li>

                  <li>
                    <strong>Trademarks: </strong>All trademarks, service marks, trade names, logos, and any other proprietary 
                    designations of Lernacy used herein are trademarks or registered trademarks 
                    of Lernacy. Users must not use or display them without prior written permission 
                    from Lernacy.
                  </li>

                  <li>
                    <strong>Other Intellectual Property: </strong>Users must not infringe upon any patent, trade secret, or other 
                    intellectual property right of Lernacy or any third party.
                  </li>

                </ul>

                <h3>
                  User-Generated Content:
                </h3>

                <p>
                  By submitting any content (e.g., text, images, videos) to the Service, you grant 
                  Lernacy a worldwide, non-exclusive, royalty-free, sub-licensable, and transferable license to 
                  use, reproduce, distribute, prepare derivative works of, display, and perform the content in 
                  connection with the Service and Lernacy's business operations.
                </p>

                <h3>
                  Notification of Infringement:
                </h3>

                <label>
                  Lernacy respects the intellectual property rights of others. If you believe that your 
                  work has been copied in a way that constitutes copyright infringement, please contact us at 
                  <strong>admin@lernacy.co.za</strong> with the following information:</label>
                <ul className='terms-list'>
                  <li>Identification of the copyrighted work claimed to have been infringed.</li>
                  <li>Identification of the allegedly infringing material on the Service.</li>
                  <li>Engaging in illegal activities or promoting illegal content.</li>
                  <li>Your contact information.</li>
                </ul>

                <h3>
                  Privacy and Data Protection
                </h3>

                <p>
                  Privacy Policy: Please refer to our Privacy Policy for detailed information on how we 
                  collect, use, and protect your personal data.
                </p>

                <p>
                  Collection of User Data: We collect certain personal information when you use our services, 
                  which may include but is not limited to your name, email address, and demographic 
                  information. This information is collected to improve our services and provide a personalized 
                  experience.
                </p>

                <label>
                  <strong>Use of User Data:</strong> Your personal data may be used for the following purposes:
                </label>
                <ul className='terms-list'>
                  <li>To operate and maintain our services.</li>
                  <li>To communicate with you, including providing customer support.</li>
                  <li>To personalize your experience and improve our services.</li>
                  <li>To analyze usage and improve the functionality of our application.</li>
                  <li>To comply with legal obligations.</li>
                </ul>

                <p>
                  <strong>Protection of User Data:</strong> We implement appropriate technical and organizational measures to 
                  safeguard your personal data from unauthorized access, use, or disclosure. These measures 
                  include encryption, access controls, and regular security assessments.
                </p>

                <p>
                  <strong>Sharing of Data with Tertiary Institutions:</strong> We may share anonymized and 
                  aggregated user data with tertiary institutions for research purposes to study the necessity 
                  and effectiveness of our application. This data sharing ensures that no personally 
                  identifiable information is disclosed, maintaining the confidentiality of our users.
                </p>

                <p>
                  <strong>Confidentiality:</strong> We ensure that any data shared with tertiary institutions 
                  for research purposes does not contain information that could enable the identification of 
                  individual users. Confidentiality is maintained throughout the research process to protect 
                  the privacy of our users.
                </p>

                <h2>
                  Termination of Service
                </h2>

                <p>
                  <strong>Termination of Service:</strong> We reserve the right to terminate or suspend access 
                  to your account and/or the Service immediately, without prior notice or liability, for any 
                  reason whatsoever, including without limitation if you breach the Terms.
                </p>

                <h2>
                  Disclaimers and Limitation of Liability
                </h2>

                <p>
                  <strong>Disclaimers and Limitation of Liability:</strong> The Service is provided on an "as is" 
                  and "as available" basis, without any warranties of any kind, express or implied, including but 
                  not limited to the warranties of merchantability, fitness for a particular purpose, or 
                  non-infringement. We do not warrant that the Service will be error-free, uninterrupted, secure, 
                  or that any defects will be corrected.
                </p>

                <p>
                  <strong>Limitation of Liability:</strong> In no event shall Lernacy, nor its directors, 
                  employees, partners, agents, suppliers, or affiliates, be liable for any indirect, 
                  incidental, special, consequential, or punitive damages, including without limitation, loss 
                  of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access 
                  to or use of or inability to access or use the Service; (ii) any conduct or content of any 
                  third party on the Service; (iii) any content obtained from the Service; and (iv) 
                  unauthorized access, use, or alteration of your transmissions or content, whether based on 
                  warranty, contract, tort (including negligence), or any other legal theory, whether or not we 
                  have been informed of the possibility of such damage, and even if a remedy set forth herein 
                  is found to have failed of its essential purpose.
                </p>

                <h3>
                  Indemnification
                </h3>

                <p>
                  <strong>Indemnification:</strong> You agree to indemnify and hold Lernacy and its subsidiaries, 
                  affiliates, officers, agents, employees, partners, and licensors harmless from any claim or 
                  demand, including reasonable attorneys' fees, made by any third party due to or arising out of 
                  your use of the Service, your violation of these Terms, or your violation of any rights of 
                  another.
                </p>

                <h3>
                  Changes to the Terms
                </h3>
                <p>
                  Changes to the Terms: We reserve the right, at our sole discretion, to modify or replace 
                  these Terms at any time. If a revision is material, we will provide at least 5 days' notice 
                  prior to any new terms taking effect. What constitutes a material change will be determined 
                  at our sole discretion.
                </p>

                <p>
                  Effective Date: By continuing to access or use our Service after any revisions become 
                  effective, you agree to be bound by the revised terms. If you do not agree to the new terms, 
                  please stop using the Service.
                </p>

                <h2>
                  Governing Law and Dispute Resolution
                </h2>
                <p>
                  Governing Law:<br />
                  This Agreement and any dispute or claim arising out of or in connection with it or its subject 
                  matter shall be governed by and construed in accordance with the laws of the South Africa's Constitution, 
                  without regard to its conflict of law principles.
                </p>

                <p>
                  Jurisdiction:<br />
                  Any legal action or proceeding arising under this Agreement shall be brought exclusively in 
                  the courts of South Africa, and the parties hereby consent to personal jurisdiction 
                  and venue therein.
                </p>

                <p>
                  Dispute Resolution:<br />
                  In the event of any dispute, controversy, or claim arising out of or relating to this 
                  Agreement, the parties agree to first attempt to resolve the dispute through negotiation 
                  between senior executives of the parties who have authority to settle the dispute. If 
                  negotiation fails, the parties agree to submit the dispute to mediation administered by 
                  [Mediation Provider], under its rules then in effect, before resorting to arbitration or 
                  litigation.
                </p>

                <p>
                  If mediation is unsuccessful, any unresolved dispute, controversy, or claim shall be finally 
                  settled by arbitration administered by [Arbitration Provider], in accordance with its rules, 
                  and the award rendered by the arbitrator(s) shall be final and binding upon the parties.
                </p>

                <p>Severability:
                  If any provision of these Terms is found to be invalid or unenforceable, that provision will be limited 
                  or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force 
                  and effect and enforceable.
                </p>

                <p>
                  Waiver:
                  The failure to enforce any right or provision of these Terms will not be deemed a waiver of 
                  such right or provision.
                </p>

                <br />
                <hr />

                <label><strong>Effective Date:</strong> August 18, 2024</label><br />
                <label><strong>Last Updated:</strong> August 18, 2024</label>

              </div>

            </div>

          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default TermsOfUse;
  
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './auth.css';

const MenuToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="menu-toggle-container" ref={menuRef}>
        <div className="toggle-button" onClick={toggleMenu}>
          <FontAwesomeIcon size="2x" icon={faBars} />
        </div>
        <div className={`menu-list ${isOpen ? 'open' : ''}`}>

          <div className='login-signup-block'>

            <Link to="/" className='menu-item-links'>
              <div className="menu-item">
                <p>
                  <strong>
                    Home
                  </strong>
                </p>
              </div>
            </Link>
          
            <Link to="/login" className='menu-item-links'>
              <div className="menu-item">
                <p>
                  <strong>
                    Login
                  </strong>
                </p>
              </div>
            </Link>

            <Link to="/signup" className='menu-item-links'>
              <div className="menu-item">
                <p>
                  <strong>
                    Signup
                  </strong>
                </p>
              </div>
            </Link>

            <hr />

            <Link to="/articles-list" className='menu-item-links'>
              <div className="menu-item">
                <p>
                  <strong>
                    Articles
                  </strong>
                </p>
              </div>
            </Link>
          
          </div>

        </div>
      </div>

      <header className="desktop-menu">
        <nav className="menu-right">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/signup">Signup</Link>
            </li>
            <li>
              <Link to="/articles-list">Articles</Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default MenuToggle;

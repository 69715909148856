import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AssessmentDevelopment = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activityName, setActivityName] = useState('');
    const [activityType, setActivityType] = useState('MCQ');
    const [questions, setQuestions] = useState([{ question: '', options: ['', '', '', ''], correctAnswer: '', score: '' }]);
const [pdf, setPdf] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [submissionResponse, setSubmissionResponse] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // To track if submission is in progress
    const [timeLimit, setTimeLimit] = useState(10);
    const MAX_QUESTIONS_MCQ = 15;
    const MAX_QUESTIONS_LAQ = 3;
    const MAX_OPTION_CHARACTERS = 200;
    const MAX_ACTIVITY_NAME_CHARACTERS = 25;

    const navigate = useNavigate();

    const handleAddQuestion = () => {
        const maxQuestions = activityType === 'MCQ' ? MAX_QUESTIONS_MCQ : MAX_QUESTIONS_LAQ;
        if (questions.length < maxQuestions) {
            if (activityType === 'MCQ') {
                setQuestions([...questions, { question: '', options: ['', '', '', ''], correctAnswer: '', score: '' }]);
            } else {
                setQuestions([...questions, { question: '', score: '' }]);
            }
        } else {
            alert(`You can only add up to ${maxQuestions} questions for ${activityType} type.`);
        }
    };

    const handleQuestionChange = (index, field, value) => {
        const newQuestions = [...questions];
        newQuestions[index][field] = value;
        setQuestions(newQuestions); 
    };

    const handleFileChange = (file) => {
        setPdf(file);
    };

    const calculateTotalScore = () => {
        const total = questions.reduce((acc, q) => acc + (parseFloat(q.score) || 0), 0);
        setTotalScore(total);
    };

    const handleOpenModal = (e) => {
        e.preventDefault();
        calculateTotalScore();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSubmissionResponse(''); // Clear the response when closing the modal
    };

    const handleProceedSubmission = async () => {
        setIsSubmitting(true);
    
        const formattedActivityName = activityName.replace(/\s+/g, '-');
        const activityNameWithScore = `${formattedActivityName}-${totalScore}`;
    
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            const formData = new FormData();
            formData.append('activityName', activityNameWithScore);
            formData.append('activityType', activityType);
            formData.append('timeLimit', timeLimit);

            questions.forEach((q, index) => {
                formData.append(`questions[${index}][question]`, q.question);
                formData.append(`questions[${index}][score]`, q.score);

                if (activityType === 'MCQ') {
                    formData.append(`questions[${index}][correctAnswer]`, q.correctAnswer);
                    q.options.forEach((opt, optIndex) => {
                        formData.append(`questions[${index}][options][${optIndex}]`, opt);
                    });
                }
            });

            if (pdf) {
                formData.append('pdf', pdf);
            }

            //console.log('Final FormData:');
            for (let pair of formData.entries()) {
                //console.log(pair[0], pair[1]); // Log everything before sending
            }
    
            const response = await fetch('https://lernacy.co.za/api/submit-activity', {
                method: 'POST',
                headers: {
                    'CSRF-Token': csrfToken, // Do NOT set 'Content-Type' manually
                },
                body: formData,
                credentials: 'include',
            });
    
            const result = await response.json();
    
            if (result.success) {
                setSubmissionResponse('Activity created successfully!');
            } else if (result.limitExceeded) {
                setSubmissionResponse('You have already submitted 2 active activities. Please try again later.');
            } else if (result.error || result.message) {
                setSubmissionResponse('Error creating activity: ' + (result.error || result.message));
            } else {
                setSubmissionResponse('An unexpected error occurred.');
            }
        } catch (error) {
            console.error('Error submitting activity:', error);
            setSubmissionResponse('An error occurred while creating the activity.');
        } finally {
            setIsSubmitting(false);
        }
    };   
     

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', { credentials: 'include' });
                const result = await response.json();

                if (result.success && result.user) {
                    setUserData(result.user);
                    if (result.user.status !== 'Admin' && result.user.status !== 'Teacher') {
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='activity-page-main-border'>
            <h1 className='activity-name-tag-h1'>Assessment Development</h1>

            <div className='filing-system-page-container'>
                <div className="activity-dev-system-page">

                    <div>
                        <div>
                            <h2>Create New Assessment Page</h2>
                            <form onSubmit={handleOpenModal} className='main-activity-info-form'>
                                <label className='main-activity-info-label'>
                                    <strong>Activity Type:</strong><br />
                                    <select value={activityType} onChange={(e) => setActivityType(e.target.value)}>
                                        <option value="MCQ">Multiple Choice Questions (MCQ)</option>
                                        <option value="LAQ">Long Answer Questions (LAQ)</option>
                                    </select>
                                </label><br />

                                <label className='main-activity-info-label'>
                                    <strong>Activity Name:</strong> <br />(e.g. Subject G10)<br />
                                    <input 
                                        type="text"
                                        value={activityName}
                                        onChange={(e) => setActivityName(e.target.value)}
                                        maxLength={MAX_ACTIVITY_NAME_CHARACTERS}
                                        required 
                                    />
                                    <span>{activityName.length}/{MAX_ACTIVITY_NAME_CHARACTERS} characters</span>
                                </label><br />

                                <label className='main-activity-info-label'>
                                    <strong>Time Limit (in minutes):</strong><br />
                                    <input
                                        type="number"
                                        value={timeLimit}
                                        onChange={(e) => setTimeLimit(Number(e.target.value))}
                                        min="1"
                                        required
                                    />
                                </label>
                                
                                <label className='pdf-upload-label'>
                                    Upload PDF (Optional):<br />
                                    <input 
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(e) => handleFileChange(e.target.files[0])}
                                />
                                </label>

                                <div className='add-more-questions-aligner'>
                                    {questions.map((q, index) => (
                                        <div key={index} className='one-question-block'>

                                        

                                            <label className='activity-question-label-block'>
                                                <strong>Question {index + 1}:</strong><br />
                                                <input
                                                    type="text"
                                                    value={q.question}
                                                    onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                                    required
                                                />
                                            </label>

                                            {activityType === 'MCQ' && (
                                                <>
                                                    {q.options.map((option, optIndex) => (
                                                        <div key={optIndex} className='activity-option-map'>
                                                            <label className='activity-option-map-label'>
                                                                Option {optIndex + 1}:<br />
                                                                <input
                                                                    type="text"
                                                                    value={option}
                                                                    maxLength={MAX_OPTION_CHARACTERS}
                                                                    onChange={(e) => {
                                                                        const newOptions = [...q.options];
                                                                        newOptions[optIndex] = e.target.value;
                                                                        handleQuestionChange(index, 'options', newOptions);
                                                                    }}
                                                                    required
                                                                /><br />
                                                                <span>{option.length}/{MAX_OPTION_CHARACTERS} characters</span>
                                                            </label>
                                                        </div>
                                                    ))}

                                                    <br />

                                                    <label className='correct-answer-div'>
                                                        Correct Answer (Option number):<br />
                                                        <input
                                                            type="text"
                                                            value={q.correctAnswer}
                                                            onChange={(e) => handleQuestionChange(index, 'correctAnswer', e.target.value)}
                                                            required
                                                        />
                                                    </label>
                                                </>
                                            )}
                                            
                                            <br />
                                            <label className='correct-answer-div'>
                                                Score for this question:<br />
                                                <input
                                                    type="number"
                                                    value={q.score}
                                                    onChange={(e) => handleQuestionChange(index, 'score', e.target.value)}
                                                    min="1"
                                                    required
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>


                                <button 
                                    type="button" 
                                    onClick={handleAddQuestion}
                                    className='add-more-question-button'
                                >
                                    Add Question
                                </button>

                                <button 
                                    type="submit" 
                                    className='submit-activity-button'
                                >
                                    Submit Activity
                                </button>
                            </form>
                        </div>
                    </div>

                    {showModal && (
                        <div className="custom-modal-overlay">
                            <div className="custom-modal">
                                <h3 className="custom-modal-title">
                                    {submissionResponse ? 'Submission Response' : 'Confirm Submission'}
                                </h3>
                                <p>{submissionResponse ? submissionResponse : 'Are you sure you want to submit this activity?'}</p>
                                <div className="custom-modal-buttons">
                                    {!submissionResponse ? (
                                        <>
                                            <button 
                                                onClick={handleCloseModal}
                                                className='custom-modal-cancel'
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={handleProceedSubmission}
                                                disabled={isSubmitting} // Disable button while submitting
                                                className='custom-modal-proceed'
                                            >
                                                {isSubmitting ? 'Submitting...' : 'Proceed'}
                                            </button>
                                        </>
                                    ) : (
                                        <button 
                                            onClick={handleCloseModal}
                                            className='custom-modal-proceed'
                                        >
                                            Close
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AssessmentDevelopment;

/*
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AssessmentDevelopment = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activityName, setActivityName] = useState('');
    const [activityType, setActivityType] = useState('MCQ');
    const [questions, setQuestions] = useState([{ question: '', options: ['', '', '', ''], correctAnswer: '', score: '' }]);
    const [showModal, setShowModal] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [submissionResponse, setSubmissionResponse] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // To track if submission is in progress
    const [timeLimit, setTimeLimit] = useState(10);
    const MAX_QUESTIONS_MCQ = 15;
    const MAX_QUESTIONS_LAQ = 3;
    const MAX_OPTION_CHARACTERS = 200;
    const MAX_ACTIVITY_NAME_CHARACTERS = 25;

    const navigate = useNavigate();

    const handleAddQuestion = () => {
        const maxQuestions = activityType === 'MCQ' ? MAX_QUESTIONS_MCQ : MAX_QUESTIONS_LAQ;
        if (questions.length < maxQuestions) {
            if (activityType === 'MCQ') {
                setQuestions([...questions, { question: '', options: ['', '', '', ''], correctAnswer: '', score: '' }]);
            } else {
                setQuestions([...questions, { question: '', score: '' }]);
            }
        } else {
            alert(`You can only add up to ${maxQuestions} questions for ${activityType} type.`);
        }
    };

    const handleQuestionChange = (index, field, value) => {
        const newQuestions = [...questions];
        newQuestions[index][field] = value;
        setQuestions(newQuestions); 
    };

    const calculateTotalScore = () => {
        const total = questions.reduce((acc, q) => acc + (parseFloat(q.score) || 0), 0);
        setTotalScore(total);
    };

    const handleOpenModal = (e) => {
        e.preventDefault();
        calculateTotalScore();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSubmissionResponse(''); // Clear the response when closing the modal
    };

    const handleProceedSubmission = async () => {
        setIsSubmitting(true); // Disable buttons during submission
    
        const formattedActivityName = activityName.replace(/\s+/g, '-');
        const activityNameWithScore = `${formattedActivityName}-${totalScore}`;
    
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            const dataToSend = {
                activityName: activityNameWithScore,
                questions,
                timeLimit,
                activityType
            };
    
            const response = await fetch('https://lernacy.co.za/api/submit-activity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(dataToSend),
                credentials: 'include',
            });
    
            const result = await response.json();
    
            if (result.success) {
                setSubmissionResponse('Activity created successfully!');
            } else if (result.limitExceeded) {
                setSubmissionResponse('You are only allowed to submit 2 activities at a time.');
            } else {
                setSubmissionResponse('Error creating activity: ' + (result.error || result.message));
            }
        } catch (error) {
            setSubmissionResponse('An error occurred while creating the activity.');
        } finally {
            setIsSubmitting(false);
        }
    };    

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', { credentials: 'include' });
                const result = await response.json();

                if (result.success && result.user) {
                    setUserData(result.user);
                    if (result.user.status !== 'Admin' && result.user.status !== 'Teacher') {
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='activity-page-main-border'>
            <h1 className='activity-name-tag-h1'>Assessment Development</h1>

            <div className='filing-system-page-container'>
                <div className="activity-dev-system-page">

                    <div>
                        <div>
                            <h2>Create New Assessment Page</h2>
                            <form onSubmit={handleOpenModal} className='main-activity-info-form'>
                                <label className='main-activity-info-label'>
                                    <strong>Activity Type:</strong><br />
                                    <select value={activityType} onChange={(e) => setActivityType(e.target.value)}>
                                        <option value="MCQ">Multiple Choice Questions (MCQ)</option>
                                        <option value="LAQ">Long Answer Questions (LAQ)</option>
                                    </select>
                                </label><br />

                                <label className='main-activity-info-label'>
                                    <strong>Activity Name:</strong> <br />(e.g. Subject G10)<br />
                                    <input 
                                        type="text"
                                        value={activityName}
                                        onChange={(e) => setActivityName(e.target.value)}
                                        maxLength={MAX_ACTIVITY_NAME_CHARACTERS}
                                        required 
                                    />
                                    <span>{activityName.length}/{MAX_ACTIVITY_NAME_CHARACTERS} characters</span>
                                </label><br />

                                <label className='main-activity-info-label'>
                                    <strong>Time Limit (in minutes):</strong><br />
                                    <input
                                        type="number"
                                        value={timeLimit}
                                        onChange={(e) => setTimeLimit(Number(e.target.value))}
                                        min="1"
                                        required
                                    />
                                </label>

                                <div className='add-more-questions-aligner'>
                                    {questions.map((q, index) => (
                                        <div key={index} className='one-question-block'>
                                            <label className='activity-question-label-block'>
                                                <strong>Question {index + 1}:</strong><br />
                                                <input
                                                    type="text"
                                                    value={q.question}
                                                    onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                                    required
                                                />
                                            </label>

                                            {activityType === 'MCQ' && (
                                                <>
                                                    {q.options.map((option, optIndex) => (
                                                        <div key={optIndex} className='activity-option-map'>
                                                            <label className='activity-option-map-label'>
                                                                Option {optIndex + 1}:<br />
                                                                <input
                                                                    type="text"
                                                                    value={option}
                                                                    maxLength={MAX_OPTION_CHARACTERS}
                                                                    onChange={(e) => {
                                                                        const newOptions = [...q.options];
                                                                        newOptions[optIndex] = e.target.value;
                                                                        handleQuestionChange(index, 'options', newOptions);
                                                                    }}
                                                                    required
                                                                /><br />
                                                                <span>{option.length}/{MAX_OPTION_CHARACTERS} characters</span>
                                                            </label>
                                                        </div>
                                                    ))}

                                                    <br />

                                                    <label className='correct-answer-div'>
                                                        Correct Answer (Option number):<br />
                                                        <input
                                                            type="text"
                                                            value={q.correctAnswer}
                                                            onChange={(e) => handleQuestionChange(index, 'correctAnswer', e.target.value)}
                                                            required
                                                        />
                                                    </label>
                                                </>
                                            )}
                                            <br />
                                            <label className='correct-answer-div'>
                                                Score for this question:<br />
                                                <input
                                                    type="number"
                                                    value={q.score}
                                                    onChange={(e) => handleQuestionChange(index, 'score', e.target.value)}
                                                    min="1"
                                                    required
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>


                                <button 
                                    type="button" 
                                    onClick={handleAddQuestion}
                                    className='add-more-question-button'
                                >
                                    Add Question
                                </button>

                                <button 
                                    type="submit" 
                                    className='submit-activity-button'
                                >
                                    Submit Activity
                                </button>
                            </form>
                        </div>
                    </div>

                    {showModal && (
                        <div className="custom-modal-overlay">
                            <div className="custom-modal">
                                <h3 className="custom-modal-title">
                                    {submissionResponse ? 'Submission Response' : 'Confirm Submission'}
                                </h3>
                                <p>{submissionResponse ? submissionResponse : 'Are you sure you want to submit this activity?'}</p>
                                <div className="custom-modal-buttons">
                                    {!submissionResponse ? (
                                        <>
                                            <button 
                                                onClick={handleCloseModal}
                                                className='custom-modal-cancel'
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={handleProceedSubmission}
                                                disabled={isSubmitting} // Disable button while submitting
                                                className='custom-modal-proceed'
                                            >
                                                {isSubmitting ? 'Submitting...' : 'Proceed'}
                                            </button>
                                        </>
                                    ) : (
                                        <button 
                                            onClick={handleCloseModal}
                                            className='custom-modal-proceed'
                                        >
                                            Close
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AssessmentDevelopment;
*/
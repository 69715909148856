import React, { useState, useEffect } from 'react';

const UserTotalPoints = () => {
    const [totalScore, setTotalScore] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const fetchTotalScore = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/user-total-points', {
                    method: 'GET',
                    credentials: 'include', // Include credentials (cookies, tokens, etc.)
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch total score');
                }

                const data = await response.json();
                setTotalScore(data.totalScore);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTotalScore();
    }, []);

    // Update window width on resize
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Conditional styles based on window width
    const styles = {
        container: {
            backgroundColor: '#000099',
            borderRadius: '50%',
            padding: '10px',
            width: '20px',
            height: '20px',
            position: 'absolute',
            left: windowWidth >= 1024 ? '140px' : '140px',
            top: windowWidth >= 1024 ? '130px' : '90px',
        },
        heading: {
            color: '#ffffff',
            fontSize: windowWidth >= 1024 ? '18px' : '18px',
            padding: '0px',
            margin: '0',
        },
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>; 

    return (
        <div style={styles.container}>
            <p style={styles.heading}>{totalScore}</p>
        </div>
    );
};

export default UserTotalPoints;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LAQSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scores, setScores] = useState({});
  const [comments, setComments] = useState({}); // State for storing comments for submissions

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/laq-submissions', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setSubmissions(data.submissions);
        } else {
          const errorData = await response.json();
          console.error('Error fetching submissions data:', errorData.message);

          if (response.status === 401) {
            setError('You are not authenticated. Please log in.');
          } else if (response.status === 403) {
            setError('Access denied. You do not have permission to view this data.');
          } else if (response.status === 404) {
            setError('User not found. Please check your user ID.');
          } else {
            setError('An unexpected error occurred. Please try again later.');
          }
        }
      } catch (error) {
        console.error('Error fetching submissions data:', error.message);
        setError('Network error. Please check your connection.');
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  // Handle score input change
  const handleScoreChange = (submissionId, questionIndex, value, maxScore) => {
    const numericValue = Number(value);

    if (numericValue > maxScore) {
      alert(`Score cannot exceed ${maxScore}. Setting score to ${maxScore}.`);
      setScores((prevScores) => ({
        ...prevScores,
        [submissionId]: {
          ...prevScores[submissionId],
          [questionIndex]: maxScore,
        },
      }));
    } else {
      setScores((prevScores) => ({
        ...prevScores,
        [submissionId]: {
          ...prevScores[submissionId],
          [questionIndex]: value,
        },
      }));
    }
  };

  // Handle comments input change
  const handleCommentChange = (submissionId, value) => {
    setComments((prevComments) => ({
      ...prevComments,
      [submissionId]: value,
    }));
  };

  // Handle score and comment submission
  const handleScoreSubmit = async (submissionId, activityId, activityType, activityName, userId) => {
    const submissionScores = scores[submissionId];
    const submissionComment = comments[submissionId];
    
    if (!submissionScores) {
      alert("Please enter a score for each question.");
      return;
    }

    try {
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) throw new Error('Failed to fetch CSRF token');
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      const postData = {
        submissionId,
        scores: submissionScores,
        activityId,
        userId, // Directly pass userId here, which is now the _id
        activityType,
        activityName,
        comments: submissionComment, 
      };

      // console.log('Data being sent to the server:', postData);

      const response = await fetch(`https://lernacy.co.za/api/submit-marked-laq-activity/${submissionId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        alert('Scores submitted successfully!');
        const updatedResponse = await fetch('https://lernacy.co.za/api/laq-submissions', {
          method: 'GET',
          credentials: 'include',
        });

        if (updatedResponse.ok) {
          const updatedData = await updatedResponse.json();
          setSubmissions(updatedData.submissions);
        } else {
          const errorData = await updatedResponse.json();
          console.error('Error fetching updated submissions:', errorData);
          alert('Failed to refresh submissions: ' + errorData.message);
        }
      } else {
        const errorData = await response.json();
        console.error('Error submitting scores:', errorData);
        alert('Failed to submit scores: ' + errorData.message);
      }
    } catch (error) {
      console.error('Error submitting scores:', error);
      alert('Error submitting scores. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const groupedSubmissions = submissions.reduce((groups, submission) => {
    const { activityId, marked } = submission;
    if (!groups[activityId._id]) {
      groups[activityId._id] = {
        submissions: [],
        marked: marked,
      };
    }
    groups[activityId._id].submissions.push(submission);
    return groups;
  }, {});

  return (
    <div className='activity-page-main-border'>
      <h1 className='activity-name-tag-h1'>LAQ Submissions</h1>
      <div className="LAQ-assessment-main">
        {Object.entries(groupedSubmissions).length > 0 ? (
          Object.entries(groupedSubmissions).map(([activityId, group]) => (
            <div key={activityId} className=''>
              <h2>Activity ID: {activityId}</h2>
              {group.marked ? (
                <h3>This section is marked.</h3>
              ) : (
                <h3>This section is not marked.</h3>
              )}
              <ul className='LAQ-submissions-list'>
                {group.submissions.map((submission) => (
                  <li key={submission._id}>
                    <p>Activity Name: {submission.activityName}</p>
                    <p>Activity Type: {submission.activityType}</p>
  
                    {/* Display submission ID */}
                    <p>Submission ID: {submission._id}</p> 
                    <p>User ID: {submission.userId._id}</p>
                    {/* Display activity ID */}
                    <p>Activity ID: {submission.activityId._id}</p>
  
                    <p>Answers:</p>
                    <div className='LAQ-submissions-list-answer'>
                      {submission.activityId.questions.map((question, index) => (
                        <div key={question._id} className='individual-questions-block'>
                          <strong>Question:</strong> {question.question} <br />
                          <strong>Answer:</strong> {submission.answers[index].answer} <br />
                          <br />
                          <strong>Max Score:</strong> {question.score}:<br />
                          <label>
                            <input
                              type="number"
                              min="0"
                              max={question.score}
                              value={scores[submission._id]?.[index] || ''}
                              onChange={(e) => handleScoreChange(submission._id, index, e.target.value, question.score)}
                            />
                          </label>
                        </div>
                      ))}
                    </div>
  
                    {/* Text input for comments */}
                    <label>
                      Comments:<br />
                      <textarea
                        value={comments[submission._id] || ''}
                        onChange={(e) => handleCommentChange(submission._id, e.target.value)}
                        className='comments-laq-activity-assess'
                      />
                    </label><br />
  
                    <button
                      className='submit-assessed-LAQ-activity'
                      onClick={() => handleScoreSubmit(
                        submission._id, 
                        submission.activityId._id, 
                        submission.activityType, 
                        submission.activityName, 
                        submission.userId._id // Correctly pass the userId._id
                      )}
                    >
                      Submit Assessed Score
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p>No submissions found.</p>
        )}
      </div>
      <br />
    </div>
  );  
};

export default LAQSubmissions;


/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LAQSubmissions = () => {
  const [submissions, setSubmissions] = useState([]); // State for submissions
  const [error, setError] = useState(null); // State for error messages
  const [loading, setLoading] = useState(true); // State for loading status
  const [scores, setScores] = useState({}); // State for storing scores for submissions

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        // Make a request to the API with credentials included
        const response = await fetch('https://lernacy.co.za/api/laq-submissions', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setSubmissions(data.submissions); // Set submissions data to state
        } else {
          const errorData = await response.json();
          console.error('Error fetching submissions data:', errorData.message);

          // Handle different response statuses
          if (response.status === 401) {
            setError('You are not authenticated. Please log in.');
          } else if (response.status === 403) {
            setError('Access denied. You do not have permission to view this data.');
          } else if (response.status === 404) {
            setError('User not found. Please check your user ID.');
          } else {
            setError('An unexpected error occurred. Please try again later.');
          }
        }
      } catch (error) {
        console.error('Error fetching submissions data:', error.message);
        setError('Network error. Please check your connection.');
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchSubmissions();
  }, []); // Run only on component mount

  // Handle score input change
  const handleScoreChange = (submissionId, questionIndex, value, maxScore) => {
    // Convert input value to a number
    const numericValue = Number(value);
    
    // Ensure the score does not exceed the maximum allowed score
    if (numericValue > maxScore) {
      alert(`Score cannot exceed ${maxScore}. Setting score to ${maxScore}.`);
      setScores((prevScores) => ({
        ...prevScores,
        [submissionId]: {
          ...prevScores[submissionId],
          [questionIndex]: maxScore, // Set to max score if input exceeds it
        },
      }));
    } else {
      // Update the score normally if within limits
      setScores((prevScores) => ({
        ...prevScores,
        [submissionId]: {
          ...prevScores[submissionId],
          [questionIndex]: value,
        },
      }));
    }
  };

  // Handle score submission
  const handleScoreSubmit = async (submissionId, activityId, activityType) => {
    const submissionScores = scores[submissionId];
  
    if (!submissionScores) {
      alert("Please enter a score for each question.");
      return;
    }
  
    try {
      // Step 1: Fetch the CSRF token from the server
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) throw new Error('Failed to fetch CSRF token');
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken; // Assuming the token is returned as { csrfToken: 'your-csrf-token' }
  
      // Step 2: Prepare the data for the POST request
      const postData = {
        submissionId,
        scores: submissionScores,
        activityId,
        activityType,
      };
  
      // Step 3: Log the data being sent to the server
      console.log('Data being sent to the server:', postData);
  
      // Step 4: Make the POST request with the CSRF token in the headers
      const response = await fetch(`https://lernacy.co.za/api/submit-marked-laq-activity/${activityId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token in the headers
        },
        body: JSON.stringify(postData), // Send the submission data
      });
  
      if (response.ok) {
        alert('Scores submitted successfully!');
  
        // Fetch updated submissions after successful score submission
        const updatedResponse = await fetch('https://lernacy.co.za/api/laq-submissions', {
          method: 'GET',
          credentials: 'include',
        });
  
        if (updatedResponse.ok) {
          const updatedData = await updatedResponse.json();
          setSubmissions(updatedData.submissions); // Update state with the new submissions
        } else {
          const errorData = await updatedResponse.json();
          console.error('Error fetching updated submissions:', errorData);
          alert('Failed to refresh submissions: ' + errorData.message);
        }
      } else {
        const errorData = await response.json();
        console.error('Error submitting scores:', errorData);
        alert('Failed to submit scores: ' + errorData.message);
      }
    } catch (error) {
      console.error('Error submitting scores:', error);
      alert('Error submitting scores. Please try again.');
    }
  };  
  
  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render error state
  if (error) {
    return <div>{error}</div>;
  }

  // Group submissions by activityId
  const groupedSubmissions = submissions.reduce((groups, submission) => {
    const { activityId, marked } = submission;
    if (!groups[activityId._id]) {
      groups[activityId._id] = {
        submissions: [],
        marked: marked // Store the marked status
      };
    }
    groups[activityId._id].submissions.push(submission);
    return groups;
  }, {});

  return (
    <div className='activity-page-main-border'>
      <h1 className='activity-name-tag-h1'>LAQ Submissions</h1>
      <div className="LAQ-assessment-main">
        {Object.entries(groupedSubmissions).length > 0 ? (
          Object.entries(groupedSubmissions).map(([activityId, group]) => (
            <div key={activityId} className=''>
              <h2>Activity ID: {activityId}</h2>
              {group.marked ? (
                <h3>This section is marked.</h3>
              ) : (
                <h3>This section is not marked.</h3>
              )}
              <ul className='LAQ-submissions-list'>
                {group.submissions.map((submission) => (
                  <li key={submission._id}>
                    <p>Activity Name: {submission.activityName}</p>
                    <p>Activity Type: {submission.activityType}</p>
                    
                    <p>Answers:</p>
                    <ul>
                      {submission.activityId.questions.map((question, index) => (
                        <li key={question._id}>
                          <strong>Question:</strong> {question.question} <br />
                          <strong>Answer:</strong> {submission.answers[index].answer} <br />
                          <br />
                          <strong>Max Score:</strong> {question.score}:<br /> 
                          <label>
                            
                            <input
                              type="number"
                              min="0"
                              max={question.score} // Set the max allowed value
                              value={scores[submission._id]?.[index] || ''}
                              onChange={(e) => handleScoreChange(submission._id, index, e.target.value, question.score)}
                            />
                          </label>
                        </li>
                      ))}
                    </ul>
                    <button className='submit-assessed-LAQ-activity' onClick={() => handleScoreSubmit(submission._id, submission.activityId._id, submission.activityType)}>
                      Submit Assessed Score
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p>No submissions found.</p>
        )}
      </div>
      <br />
    </div>
  );
};

export default LAQSubmissions;
*/